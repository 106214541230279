<template>
  <div class="valve-module-main-content main-content" v-loading="loading">
    <div class="crt-content">
      <div class="save-button" v-show="!isReadonly">
        <el-button
          @click="open_save"
          size="mini"
          type="success"
          class="commonBtn"
          :disabled="isReadonly"
        >
          保存
        </el-button>
      </div>

      <el-button @click="isReadonly = !isReadonly" size="mini" class="commonBtn" type="primary">
        {{ isReadonly === true ? '编辑' : '取消' }}
      </el-button>
      <el-button
        icon="el-icon-s-platform"
        @click="infoPreview"
        size="mini"
        class="commonBtn"
        type="primary"
      >
        报告预览
      </el-button>
      <el-button
        icon="el-icon-edit"
        @click="reportSign"
        size="mini"
        class="commonBtn"
        type="success"
      >
        {{ signStatus ? '解签' : '报告签名' }}
      </el-button>
    </div>
    <div class="body">
      <div class="valve-basic-information">
        <div class="valve-basic-information-body">
          <!-- 冠脉 -->
          <el-card style="width: 50%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">鞘管大小</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    v-model="MitralTricuspidInfo.sheathSize"
                    size="mini"
                    unit="F"
                  />
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">鞘置入时间</div>
                <div v-if="!isReadonly">
                  <date-panel
                    v-model="MitralTricuspidInfo.sheathGoInTime"
                    type="datetime"
                    align="center"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    :default-value="defaultTime"
                  ></date-panel>
                </div>
                <div v-if="isReadonly">
                  <el-tag type="info">{{ MitralTricuspidInfo.sheathGoInTime }}</el-tag>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">鞘拔除时间</div>
                <div v-if="!isReadonly">
                  <date-panel
                    v-model="MitralTricuspidInfo.sheathOutTime"
                    type="datetime"
                    align="center"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm"
                    format="yyyy-MM-dd HH:mm"
                    @change="geiSheathOutTime"
                    :default-value="defaultTime"
                  ></date-panel>
                </div>
                <div v-if="isReadonly">
                  <el-tag type="info">{{ MitralTricuspidInfo.sheathOutTime }}</el-tag>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">鞘置入时长</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder=""
                    v-model="MitralTricuspidInfo.sheathLength"
                    size="mini"
                    unit="min"
                    :disabled="true"
                  >
                    <template slot="append">min</template>
                  </cqt-input>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 手术 -->
          <el-card style="width: 50%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <!-- <div class="input-form-item">
                <div class="input-form-item-label">手术方式</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_operateType"
                  :value.sync="MitralTricuspidInfo.operateType"
                  @update:value="changeOperateType"
                />
              </div>
              <div class="input-form-item" v-if="repairSystemIsShow === true">
                <div class="input-form-item-label">修复系统</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_repairSystem"
                  :value.sync="MitralTricuspidInfo.repairSystem"
                />
              </div> -->
              <!-- 瓣膜 -->
              <!-- <div class="input-form-item" v-if="repairSystemIsShow === false">
                <div class="input-form-item-label">瓣膜品牌</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="MitralTricuspidInfo.valveBrand"
                  />
                </div>
              </div>
              <div class="input-form-item" v-if="repairSystemIsShow === false">
                <div class="input-form-item-label">瓣膜型号尺寸</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="text"
                    size="mini"
                    v-model="MitralTricuspidInfo.valveModel"
                  />
                </div>
              </div> -->
              <!-- <div class="input-form-item" v-if="repairSystemIsShow === false">
                <div class="input-form-item-label">瓣膜尺寸</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    size="mini"
                    unit="mm"
                    min="0"
                    v-model="MitralTricuspidInfo.valveSize"
                  >
                    <template slot="append">mm</template>
                  </cqt-input>
                </div>
              </div> -->

              <div class="input-form-item">
                <div class="input-form-item-label">麻醉方式</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/AO_anesthesia"
                  :value.sync="MitralTricuspidInfo.anesthesiaType"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">血管入路</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_puncture_site"
                  :value.sync="MitralTricuspidInfo.vascularApproach"
                />
              </div>

              <div
                v-show="MitralTricuspidInfo.vascularApproach?.includes('其他')"
                class="input-form-item"
              >
                <div class="input-form-item-label">其他入路方式</div>
                <cqt-input
                  class="input_has_unit"
                  placeholder="请输入"
                  type="text"
                  v-model="MitralTricuspidInfo.otherApproachType"
                  size="mini"
                  :isReadonly="isReadonly"
                ></cqt-input>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术中影像支持</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_image_support"
                  :value.sync="MitralTricuspidInfo.imageSupport"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">房间隔穿刺高度</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="text"
                    v-model="MitralTricuspidInfo.transseptalPunctureHeight"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">房间隔穿刺位置</div>

                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_puncture_position"
                  :value.sync="MitralTricuspidInfo.transseptalPuncturePosition"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">有无特殊情况</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.exceptionalCase"
                />
              </div>
              <div class="input-form-item" v-if="MitralTricuspidInfo.exceptionalCase === '有'">
                <div class="input-form-item-label">特殊情况描述</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="textarea"
                    :rows="2"
                    v-model="MitralTricuspidInfo.exceptionalCaseMemo"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">术前左房压力</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    v-model="MitralTricuspidInfo.laPressure"
                    size="mini"
                    unit="mmHg"
                    type="number"
                    min="0"
                  >
                    <template slot="append">mmHg</template>
                  </cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">器械</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_instrument"
                  :value.sync="MitralTricuspidInfo.instrument"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="mitral-tricuspid-information">
        <mitral-tricuspid-information
          :isReadonly="isReadonly"
          :allDetail="allDetail"
          :formId="formData.formId"
          ref="MitralTricuspidInformation"
        ></mitral-tricuspid-information>
      </div>

      <div class="valve-basic-information valve-basic-information-label">
        <span class="inner-title">
          <span style="margin-right: 10px">术后即刻超声心动图检查</span>
        </span>

        <div v-if="echoTypeIsShow === true" class="valve-basic-information-body">
          <!-- 二尖瓣 -->
          <el-card style="width: 40%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">二尖瓣反流分级</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_mrLevel"
                  :value.sync="cardiogram.mrLevel"
                />
              </div>
              <!-- <div class="input-form-item">
                <div class="input-form-item-label">二尖瓣反流颈宽度</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.mrWidth"
                    size="mini"
                    unit="cm，3D-VCA"
                    min="0"
                  ></cqt-input>
                </div>
              </div> -->
              <div class="input-form-item">
                <div class="input-form-item-label">二尖瓣最大压差</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.mvpgmax"
                    size="mini"
                    unit="mmHg"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">二尖瓣平均压差</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.mvpgmean"
                    size="mini"
                    unit="mmHg"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">二尖瓣有效瓣口面积EOA</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.eoa"
                    size="mini"
                    unit="cm2  3D"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 12rem">
                  二尖瓣环前后径（cm，LVOT 3D AP径收缩/舒张）
                </div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.mvadiam"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label" style="width: 12rem">
                  二尖瓣环隔-外径（cm，LVOT 3D LM径收缩/舒张）
                </div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.outsideDiameter"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>
            </div>
          </el-card>
          <!-- 评估 -->
          <el-card style="width: 40%" :body-style="{ padding: '20px' }">
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">左上肺静脉反流</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_lspv"
                  :value.sync="cardiogram.lspv"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">右上肺静脉反流</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_rspv"
                  :value.sync="cardiogram.rspv"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">房间隔分流（房间隔穿刺术）</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_iasShunt"
                  :value.sync="cardiogram.iasShunt"
                />
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">房间隔分流束宽度</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.iasShuntWidth"
                    size="mini"
                    unit="cm"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">房间隔分流压差</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    type="number"
                    v-model="cardiogram.iasshuntDifferentialPressure"
                    size="mini"
                    unit="mmHg"
                  ></cqt-input>
                </div>
              </div>
              <div class="input-form-item">
                <div class="input-form-item-label">心包积液</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_peLevel"
                  :value.sync="cardiogram.peLevel"
                />
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">备注</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit_text"
                    type="textarea"
                    :rows="2"
                    v-model="cardiogram.notes"
                    size="mini"
                  ></cqt-input>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="valve-basic-information">
        <div class="valve-basic-information-body">
          <!-- 血管 -->
          <el-card style="width: 100%" :body-style="{ padding: '20px' }">
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">辐射剂量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getrayQuantity"
                    v-model="MitralTricuspidInfo.rayQuantity"
                    size="mini"
                    unit="mGy"
                    min="0"
                    max="500"
                  >
                    <template slot="append">mGy</template>
                  </cqt-input>
                </div>
              </div>

              <div class="input-form-item">
                <div class="input-form-item-label">曝光时间</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getrayQuantity"
                    v-model="MitralTricuspidInfo.exposureTime"
                    size="mini"
                    unit="min"
                    min="0"
                  >
                    <template slot="append">min</template>
                  </cqt-input>
                </div>
              </div>
            </div>
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">切口闭合</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_vessel_obturator"
                  :value.sync="MitralTricuspidInfo.vesselObturator"
                />
              </div>
            </div>
            <div class="input-form-card">
              <div class="input-form-item">
                <div class="input-form-item-label">出血量</div>
                <div>
                  <cqt-input
                    :isReadonly="isReadonly"
                    class="input_has_unit"
                    placeholder="请输入"
                    type="number"
                    @input="getoutTransAmount"
                    v-model="MitralTricuspidInfo.outTransAmount"
                    size="mini"
                    unit="ml"
                    min="0"
                    max="5000"
                  >
                    <template slot="append">ml</template>
                  </cqt-input>
                </div>
              </div>

              <!-- 输血 -->
              <Transfusion
                :isReadonly="isReadonly"
                ref="Transfusion"
                :apiOptions="apiOptions"
                :surgeryInfo="MitralTricuspidInfo"
                :flexWrap="'warp'"
              />
            </div>
            <div class="input-form">
              <div class="input-form-item">
                <div class="input-form-item-label">夹子与瓣叶结构缠绕</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_peLevel"
                  :value.sync="MitralTricuspidInfo.clampLeafletWrapping"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <div class="valve-basic-information" style="margin-top: 20px">
        <span class="inner-title">
          并发症
          <el-radio-group v-model="MitralTricuspidInfo.complication">
            <el-radio label="有">有</el-radio>
            <el-radio label="无">无</el-radio>
          </el-radio-group>
        </span>
        <div class="valve-basic-information-body">
          <el-card style="width: 100%" :body-style="{ padding: '20px' }">
            <div class="input-form">
              <!-- <div class="input-form-item">
                <div class="input-form-item-label">有无并发症</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.complication"
                />
              </div> -->
              <div class="input-form-item" v-show="MitralTricuspidInfo.complication === '有'">
                <div class="input-form-item-label">有无脱离</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.disengage"
                />
              </div>
              <div
                class="input-form-item"
                v-if="
                  MitralTricuspidInfo.complication === '有' &&
                  MitralTricuspidInfo.disengage === '有'
                "
              >
                <div class="input-form-item-label">脱离类型</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_disengage_type"
                  :value.sync="MitralTricuspidInfo.disengageType"
                />
              </div>
            </div>
            <div class="input-form" v-show="MitralTricuspidInfo.complication === '有'">
              <div class="input-form-item">
                <div class="input-form-item-label">有无栓塞</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.embolism"
                />
              </div>
              <div
                class="input-form-item"
                v-show="
                  MitralTricuspidInfo.complication === '有' && MitralTricuspidInfo.embolism === '有'
                "
              >
                <div class="input-form-item-label">栓塞类型</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_embolism_type"
                  :value.sync="MitralTricuspidInfo.embolismType"
                />
              </div>
            </div>
            <div class="input-form" v-show="MitralTricuspidInfo.complication === '有'">
              <div class="input-form-item">
                <div class="input-form-item-label">术中循环崩溃心脏按压</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Yes_No"
                  :value.sync="MitralTricuspidInfo.cardiacMassage"
                />
              </div>
            </div>
            <div class="input-form" v-show="MitralTricuspidInfo.complication === '有'">
              <div class="input-form-item">
                <div class="input-form-item-label">有无新发二尖瓣相关损伤</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.relatedInjuries"
                />
              </div>
              <div
                class="input-form-item"
                v-show="
                  MitralTricuspidInfo.complication === '有' &&
                  MitralTricuspidInfo.relatedInjuries === '有'
                "
              >
                <div class="input-form-item-label">新发二尖瓣相关损伤</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_related_injuries_type"
                  :value.sync="MitralTricuspidInfo.relatedInjuriesType"
                />
              </div>
            </div>
            <div class="input-form" v-show="MitralTricuspidInfo.complication === '有'">
              <div class="input-form-item">
                <div class="input-form-item-label">有无血管并发</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/Have_Not"
                  :value.sync="MitralTricuspidInfo.vascularComplications"
                />
              </div>
              <div
                class="input-form-item"
                v-show="
                  MitralTricuspidInfo.complication === '有' &&
                  MitralTricuspidInfo.vascularComplications === '有'
                "
              >
                <div class="input-form-item-label">血管并发类型</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_vascular_complications"
                  :value.sync="MitralTricuspidInfo.vascularComplicationsType"
                />
              </div>
              <div
                class="input-form-item"
                v-show="
                  MitralTricuspidInfo.complication === '有' &&
                  MitralTricuspidInfo.vascularComplications === '有'
                "
              >
                <div class="input-form-item-label">治疗方式</div>
                <cqt-select
                  :isReadonly="isReadonly"
                  activeUrl="/v1/webconsole/comboData/get/MT_treatment_methods"
                  :value.sync="MitralTricuspidInfo.treatmentMethods"
                />
              </div>
            </div>
          </el-card>
        </div>
      </div>

      <!-- 并发症 -->
      <!-- <ComplicationForm
        :isReadonly="isReadonly"
        :complicationData="MitralTricuspidInfo"
        complicationUrl="/v1/webconsole/comboData/get/MT_complication"
        finalDiagnosisUrl="/v1/webconsole/comboData/get/MT_post_diagnosis"
        ref="ComplicationForm"
      /> -->

      <!-- 备注 -->
      <div class="remarkInfo">
        <div class="remarkTitle">备注</div>
        <div class="get-undo-redo-main-content-body">
          <el-card>
            <cqt-input
              :isReadonly="isReadonly"
              :rows="3"
              placeholder="请输入备注内容"
              type="textarea"
              v-model="MitralTricuspidInfo.notes"
              size="mini"
            />
          </el-card>
        </div>
      </div>

      <!-- 术后诊疗计划 -->
      <div class="post-operative-medical-diagnosis">
        <post-medical-diagnosis
          :isReadOnly="isReadonly"
          :templateData="templateDiagnosisData"
          :surgeryInfo="MitralTricuspidInfo"
          :operation="operation"
          :currentPatient="currentPatient"
          ref="PostMedicalDiagnosis"
        />
      </div>

      <!-- 手术经过 -->
      <div class="surgery-procedure">
        <surgery-procedure
          :isReadOnly="isReadonly"
          :templateData="templateProcedureData"
          :surgeryInfo="MitralTricuspidInfo?.studyProcess"
          :operation="operation"
          @infoPreview="infoPreview"
          ref="SurgeryProcedure"
        />
      </div>
      <!-- 签名 -->
      <sign-report :operation="operation" @signComplete="signComplete" ref="SignReport" />
    </div>
    <!-- pdf -->
    <el-dialog title="报告详情" :visible.sync="dialogVisible" width="70%" :modal="false">
      <div>
        <iframe :src="this.report_address" style="height: 890px; width: 100%"></iframe>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import MitralTricuspidInformation from './components/MitralTricuspidInformation'

// import ComplicationForm from '../components/ComplicationForm.vue'
import SurgeryProcedure from './components/SurgeryProcedure.vue'
import SurgeryResult from '../components/SurgeryResult.vue'
import PostMedicalDiagnosis from './components/PostMedicalDiagnosis.vue'

import SignReport from '../components/SignReport.vue'

import { differMin } from '../../../utils'

// cqt
import CqtInput from '../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../components/cqt/CqtSelect.vue'
import CqtRadio from '../../../components/cqt/CqtRadio.vue'
import { PDF_HOST } from '../../../utils/globalVariable'

import DatePanel from '../components/date-picker/index'

import Transfusion from '../components/Transfusion.vue'

import { mapState } from 'vuex'

export default {
  components: {
    SurgeryProcedure,
    SurgeryResult,
    PostMedicalDiagnosis,
    MitralTricuspidInformation,
    // ComplicationForm,
    SignReport,

    DatePanel,

    CqtSelect,
    CqtInput,
    CqtRadio,

    Transfusion
  },
  data() {
    return {
      loading: true,
      isReadonly: false,
      report_address: '',
      dialogVisible: false,
      signStatus: false,
      reportInfo: null,
      // 手术经过结果信息
      operationName: '二三尖瓣',
      surgeryInfo: null,
      operation: 'Mitral',
      templateProcedureData: {
        templateName: '手术经过'
      },
      templateResultData: {
        templateName: '手术结果'
      },
      templateAdviceData: {
        templateName: '术后医嘱'
      },
      templateDiagnosisData: {
        templateName: '术后去向'
      },

      changeValue: '',
      echoTypeIsShow: true,
      repairIsShow: false,
      MitralTricuspidInfo: {
        operationId: null, //手术ID
        sheathGoInTime: null, //鞘置入时间
        sheathOutTime: null, //鞘拔除时间
        sheathLength: '',
        // operateType: '二尖瓣修复术', //手术方式
        valveBrand: null, //二尖瓣置换术：瓣膜品牌
        valveModel: null, //二尖瓣置换术：瓣膜型号
        valveSize: null, //二尖瓣置换术：瓣膜尺寸
        repairSystem: null, //二尖瓣修复术：修复系统
        approach: null, //入路途径
        laPressure: null, //术前左房压力(mmHg)
        echoCardiogram: null, //术后即刻超声心动图检查(是/否)
        imageSupport: null, //术中影像支持
        transseptalPunctureHeight: null, // 房间隔穿刺高度
        transseptalPuncturePosition: null, // 房间隔穿刺位置
        exceptionalCase: null, // 有无特殊情况
        exceptionalCaseMemo: null, // 特殊情况描述
        instrument: null, // 器械
        contrastAgentDose: null, //造影剂剂量(ml)
        operationTime: null, //手术时间(min)
        notes: null, //备注
        approachType: null, // 入路方式
        anesthesiaType: null, // 麻醉方式
        exposureTime: null, // 曝光时间
        vesselObturator: null, // 曝光时间
        vesslClosure: null, // 血管闭合方式
        vesselObturator: null, // 血管闭合器种类
        isBleeding: '否', // 是否出血
        outTransAmount: null, // 出血量
        protamine: null, // 鱼精蛋白
        protamineDose: null,
        rayQuantity: null,
        sheathSize: null,
        transfusion: null,
        transfusionAmount: null,
        transfusionComponent: null,
        vascularApproach: null,
        postOperativeBraking: null,
        complication: null,
        disengage: null,
        disengageType: null,
        embolism: null,
        embolismType: null,
        cardiacMassage: null,
        relatedInjuries: null,
        relatedInjuriesType: null,
        cardiacMassage: null,
        vascularComplications: null,
        vascularComplicationsType: null,
        clampLeafletWrapping: null, //
        treatmentMethods: null
      },
      cardiogram: {
        mitralTricuspidId: '', //主表ID
        operationId: null, //手术ID
        mrLevel: null, //二尖瓣反流分级
        mrWidth: null, //二尖瓣反流颈宽度（cm，3D-VCA）
        mvpgmax: null, //二尖瓣最大压差（mmHg）
        mvpgmean: null, //二尖瓣平均压差（mmHg）
        eoa: null, //二尖瓣有效瓣口面积EOA（cm2  3D）
        mvadiam: null, //三腔心二尖瓣环前后径（cm，舒张期）
        outsideDiameter: null, //
        lspv: null, //左上肺静脉反流（有/无）
        rspv: null, //右上肺静脉反流（有/无）
        iasShunt: null, //房间隔分流（房间隔穿刺术）
        iasShuntWidth: null, //房间隔分流束宽度（cm）
        iasshuntDifferentialPressure: null, //
        peLevel: null, //心包积液分级
        notes: null //备注
      },

      selectedRow: null,
      tableKey: null,
      cancerInfo: {
        firstTACE: null, //首次TACE日期
        punctureSite: null, //穿刺部位
        operativeStyle: null, //术式
        medicationMode: null //用药方式
      },
      liverCancerPreInspectionInfo: {
        liverFunction: null, //肝功能
        routineBlood: null, //血常规
        tumorMarker: null, //肿瘤标注物
        hepatitis: null //肝炎
      },

      liverCancermRecistInfo: {
        firstTargetLesion: null, //1st.靶病灶疗效
        secondTargetLesion: null, //2nd.靶病灶疗效
        thirdTargetLesion: null, //3rd.靶病灶疗效
        lastTargetLesion: null //末次靶病灶疗效
      },
      liverCancerEvaluationInfo: {
        portalVein: null, //有无门静脉癌栓治疗
        aggressiveTreatment: null, //胆道或肝静脉侵犯治疗
        tumorTreatment: null, //肝外转移瘤治疗
        followUpTreatment: null, //后续治疗方案
        survivalState: null, //生存状态
        dateOfDeath: null, //死亡日期
        causeOfDeath: null, //死亡原因
        survivalTime: null, //生存时间
        survivalTimeUnit: null, //生存时间单位
        combinationTherapy: null, //有无联合其他治疗
        taceComplications: null //TACE栓塞后并发症
      },

      defaultTime: new Date(),
      allDetail: {},
      allInitInfo: {}, // 初始化值
      menuListData: null,
      // 通用表单
      formData: {
        formId: '',
        id: '',
        items: []
      },
      universalStudy: {
        // 公共字段
        postDiagnosis: undefined, //术后诊断
        studyProcess: undefined, //手术经过
        // studyResult: undefined, //手术结果
        o2: undefined, //吸氧方式
        anesthesiaType: undefined, //麻醉方式
        vascularApproach: undefined, //血管入路
        otherApproachType: undefined, // 其他入路方式
        approachType: undefined, //入路方式
        superHardGW: undefined, //超硬导丝
        rayQuantity: undefined, //射线量(mGy)
        exposureTime: undefined, //曝光时间(min)
        vesselObturator: undefined, //曝光时间(min)
        sheathSize: undefined, //鞘管大小
        sheathGoInTime: undefined, //鞘置入时间
        sheathOutTime: undefined, //鞘拔除时间
        sheathLength: undefined, //鞘置入时长 min
        isBleeding: '否', // 是否出血
        outTransAmount: undefined, //出血量
        transfusion: undefined, //是否输血
        transfusionComponent: undefined, //输血成分
        transfusionAmount: undefined, //输血量
        protamine: undefined, //鱼精蛋白
        protamineDose: undefined, //鱼精蛋白剂量（mg）
        vesslClosure: undefined, //血管闭合方式
        vesselObturator: undefined, //血管闭合器种类
        vesselObturatorOther: undefined, //血管闭合器种类其他
        intervention: undefined, //特殊操作
        pacingThreshold: undefined, //临时起搏阈值
        pacingFrequency: undefined, //临时起搏频率
        pacingOutput: undefined, //临时起搏输出
        pacingPerception: undefined, //临时起搏感知
        patientFeedback: undefined, //术后患者反映
        skinExamination: undefined, //术后皮肤检查
        vascularPulsation: undefined, //术后穿刺处血管搏动
        postOperativeBraking: undefined, //术后制动
        transferMethod: undefined, //转运方式
        postoperativeDestination: undefined, //术后去向
        riskAssessment: undefined, //风险评估
        complication: undefined, //并发症
        postAdvice: undefined, //术后医嘱
        postNotes: undefined, //术后注意事项
        treatmentPlanId: undefined, //术后诊疗计划id
        treatmentPlan: undefined, //术后诊疗,

        otherTransfusionComponent: undefined, //其他输血成分描述,
        wholeBloodVolume: undefined, //全血输血量,
        plasmaVolume: undefined, //血浆输血量,
        plateletVolume: undefined, //血小板输血量,
        redBloodCellVolume: undefined, //红细胞输血量,
        otherVolume: undefined, //其他成分血输血量,
        otherSuperHardGW: null, // 超硬导丝其他描述
        vesslClosureOther: undefined, // 血管闭合方式其他
        // postDiagnosisOther: undefined, // 术后诊断其他
        // complicationOther: undefined, // 并发症其他

        occluderType: undefined, // 封堵器类型
        occluderOtherType: undefined, // 封堵器类型其他
        staplerType: undefined, // 缝合器类型
        staplerOtherType: undefined, // 缝合器类型其他
        pressureTime: undefined // 压迫时间
      },
      itemArr: [
        {
          // 缘对缘修复
          elementId: 'mitralTeer',
          value: null,
          extendTable: null
        },
        {
          // 二尖瓣术后即刻超声心动图检查
          elementId: 'mitralEcho',
          value: null,
          extendTable: null
        }
      ],
      apiOptions: {
        transfusionComponentUrl: 'AO_composition_blood_transfusion' // 输血成分
      }
    }
  },
  props: {
    info: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  },
  watch: {
    info: {
      handler(val) {
        if (val && val.uuid) {
          this.getMenuIndex()
        }
      },
      immediate: true
    },
    'MitralTricuspidInfo.complication': {
      handler(val) {
        if (val && val === '无') {
          this.MitralTricuspidInfo.disengage = ''
          this.MitralTricuspidInfo.disengageType = ''
          this.MitralTricuspidInfo.embolism = ''
          this.MitralTricuspidInfo.embolismType = ''
          this.MitralTricuspidInfo.cardiacMassage = ''
          this.MitralTricuspidInfo.relatedInjuries = ''
          this.MitralTricuspidInfo.relatedInjuriesType = ''
          this.MitralTricuspidInfo.vascularComplications = ''
          this.MitralTricuspidInfo.vascularComplicationsType = ''
          this.MitralTricuspidInfo.treatmentMethods = ''
        }
      }
    },
    // repairSystemIsShow: {
    //   handler(val) {
    //     if (val === true) {
    //       this.MitralTricuspidInfo.valveBrand = ''
    //       this.MitralTricuspidInfo.valveModel = ''
    //       this.MitralTricuspidInfo.valveSize = ''
    //     } else if (val === false) {
    //       this.MitralTricuspidInfo.repairSystem = ''
    //     }
    //   },
    //   immediate: true
    // },
    // 时间
    'MitralTricuspidInfo.sheathGoInTime': function (val) {
      if (!this.MitralTricuspidInfo.sheathOutTime) return
      if (val) {
        const time = this.differMin(val, this.MitralTricuspidInfo.sheathOutTime)
        if (time < 0) {
          return (this.MitralTricuspidInfo.sheathLength = 0)
        }
        this.MitralTricuspidInfo.sheathLength = time
      } else {
        this.MitralTricuspidInfo.sheathLength = null
      }
    },
    'MitralTricuspidInfo.sheathOutTime': function (val) {
      if (!this.MitralTricuspidInfo.sheathGoInTime) return
      if (val) {
        const time = this.differMin(this.MitralTricuspidInfo.sheathGoInTime, val)
        if (time < 0) {
          return (this.MitralTricuspidInfo.sheathLength = 0)
        }
        this.MitralTricuspidInfo.sheathLength = time
      } else {
        this.MitralTricuspidInfo.sheathLength = null
      }
    }
  },
  mounted() {},
  created() {},
  methods: {
    differMin,
    // 1
    getMenuIndex() {
      if (this.$store.state.menuList.length > 0 && !this.$route.path.includes('follow')) {
        for (let i = 0; i < this.$store.state.menuList.length; i++) {
          const element = this.$store.state.menuList[i]
          if (element.menuCode.includes(this.$route.path)) {
            this.menuListData = element
          }
        }
        this.loading = true
        this.getTable()
      } else {
        let url = `/v1/webconsole/study/operation/menu/${this.info.uuid}`
        this.$api.get(url).then((res) => {
          let menuList = []
          if (res.data && res.data.data) {
            menuList = res.data.data.map((item) => {
              return {
                ...item,
                menuCode: `/Detail/${item.menuCode}`,
                code: item.menuCode
              }
            })
            for (let i = 0; i < menuList.length; i++) {
              const element = menuList[i]
              if (
                element.menuCode.includes(
                  JSON.parse(localStorage.getItem('followPath')).replace('/follow/info', '')
                )
              ) {
                this.menuListData = element
              }
            }
            this.getTable()
          }
        })
      }
    },
    // 2 获取表单id
    getTable() {
      let url = `/v1/webconsole/eform/universal/form/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api.get(url).then(({ data: res }) => {
        if (res && res.data) {
          this.universalForm = res.data
          this.formData.formId = res.data[0].formId
          let formUrl = `/v1/webconsole/eform/universal/formdata/${this.info.uuid}/${this.menuListData.moduleId}`
          let data = {
            formId: this.formData.formId
          }
          this.$api.get(formUrl, data).then(({ data: res }) => {
            if (res && res.data) {
              if (res.data.length !== 0) {
                this.formList = res.data
                this.formData.id = res.data[0].id
                this.getTableData()
              } else {
                // 如果没有数据则使用初始化
                this.getDefaultInfo()
              }
              this.getReportInfo() // 查询报告状态
            }
          })
        }
      })
      setTimeout(() => {
        this.loading = false
      }, 500)
    },
    // 3  查询基本数据（公共） 获取表单数据（私有）
    getTableData() {
      let url = `/v1/webconsole/eform/fields/${this.formList[0].formId}/${this.formList[0].id}`
      this.$api.get(url).then(({ data: res }) => {
        let obj = {}
        if (res && res.data) {
          for (let i in res.data) {
            if (!this.universalStudy?.hasOwnProperty(i)) {
              obj[i] = res.data[i].valueString
            }
            if (res.data[i].dataType === 'dataTable') {
              res.data[i].extendTable = []
            }
            // 术后即刻超声心动图检查
            for (let item in this.cardiogram) {
              if (item === i) {
                this.cardiogram[item] = res.data[i].valueString
              }
            }
          }
          this.MitralTricuspidInfo = { ...this.MitralTricuspidInfo, ...obj }
          this.allDetail = res.data
          this._getEchoCardiogram() // 获取术后即刻超声心动图检查
        }
      })

      // 查询基本数据（公共）
      this.$api
        .get(
          `/v1/webconsole/study/operation/universal/${this.info.uuid}?moduleCode=${this.menuListData.code}`
        )
        .then(({ data: res }) => {
          if (res && res.data) {
            if (res.data) {
              let obj = {}
              for (let i in res.data) {
                for (let j in this.universalStudy) {
                  if (j === i) {
                    obj[i] = res.data[i]
                  }
                }
              }
              this.MitralTricuspidInfo = { ...this.MitralTricuspidInfo, ...obj }
            }
          }
        })
    },
    // 初始化数据
    getDefaultInfo() {
      if (this.info) {
        this.$api
          .get(
            `/v1/webconsole/eform/universal/init/${this.info.uuid}/${this.menuListData.moduleId}`
          )
          .then((res) => {
            if (res.data && res.data.data) {
              console.log('init', res)
              if (this.currentPatient) {
                this.MitralTricuspidInfo.anesthesiaType = this.currentPatient.anesthesiaMethod
              }
              // this.allInitInfo = res.data.data
              // if (
              //   this.coronaryInfo.coronaryIndex === null ||
              //   this.coronaryInfo.coronaryIndex === ''
              // ) {
              //   for (let j in this.allInitInfo.coronaryInfo) {
              //     for (let k in this.coronaryInfo) {
              //       if (k === j) {
              //         this.coronaryInfo[k] = this.allInitInfo.coronaryInfo[j]
              //       }
              //     }
              //   }
              // }
            }
          })
      }
    },
    // 获取术后即刻超声心动图检查
    _getEchoCardiogram() {
      let url = `/v1/webconsole/eform/extendfields/${this.formData.formId}/${this.allDetail.mitralEcho.id}`
      let formData = new FormData()
      formData.append('elementName', 'mitralEcho')
      this.$api.post(url, formData).then(({ data: res }) => {
        if (res && res.data) {
          for (let item in res.data[0]) {
            if (this.cardiogram.hasOwnProperty(item)) {
              this.cardiogram[item] = res.data[0][item]
            }
          }
        }
      })
    },
    geiSheathOutTime(val) {
      if (!this.MitralTricuspidInfo.sheathGoInTime) return
      if (val) {
        const time = this.differMin(this.MitralTricuspidInfo.sheathGoInTime, val)
        if (time < 0) {
          this.MitralTricuspidInfo.sheathOutTime = null
          this.$message({
            message: '鞘拔除时间应大于鞘置入时间',
            type: 'warning'
          })
        }
      }
    },
    getrayQuantity() {
      if (this.MitralTricuspidInfo.rayQuantity) {
        if (this.MitralTricuspidInfo.rayQuantity < 0) this.MitralTricuspidInfo.rayQuantity = 0
      }
    },
    getoutTransAmount() {
      if (this.MitralTricuspidInfo.outTransAmount) {
        if (this.MitralTricuspidInfo.outTransAmount < 0) this.MitralTricuspidInfo.outTransAmount = 0
      }
    },
    gettransfusionAmount() {
      if (this.MitralTricuspidInfo.transfusionAmount) {
        if (this.MitralTricuspidInfo.transfusionAmount < 0)
          this.MitralTricuspidInfo.transfusionAmount = 0
      }
    },
    getprotamineDose() {
      if (this.MitralTricuspidInfo.protamineDose) {
        if (this.MitralTricuspidInfo.protamineDose < 0) this.MitralTricuspidInfo.protamineDose = 0
      }
    },

    getReportInfo() {
      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then((res) => {
          if (res.data.status === 200) {
            this.reportInfo = res.data.data
            if (res.data.data?.signed === 'Y') {
              this.signStatus = true
            }
          }
        })
    },
    // 签名
    reportSign() {
      // 解签
      if (this.signStatus) {
        this.$refs.SignReport.showRelievePwdDialog()
      } else {
        this._save(() => {
          this.$refs.SignReport.getReportSignType()
        })
      }
    },
    signComplete(val) {
      this.signStatus = val
    },

    // 报告
    infoPreview() {
      const pdfLoading = this.$loading({
        lock: true,
        text: '报告加载中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
        fullscreen: false
      })
      this.report_address = null

      this.$api
        .get(`/v1/webconsole/report/list/doctor/${this.info.uuid}/${this.menuListData.code}`)
        .then(
          (res) => {
            pdfLoading.close()
            if (!res.data.data || res.data.data?.signed === 'N') {
              // 生成手术报告单
              this._save(() => {
                this.$api
                  .get(
                    `/v1/webconsole/report/save/doctor/${this.info.uuid}/${this.menuListData.code}`
                  )
                  .then(
                    (response) => {
                      pdfLoading.close()
                      if (response.data && response.data.data) {
                        if (!response.data.data.pdfFileName) {
                          return this.$message.info('暂无报告')
                        }
                        var s = encodeURI(response.data.data.pdfFileName)
                        if (response.data.data.pdfFileName.includes('http')) {
                          this.report_address = s
                        } else {
                          this.report_address =
                            PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
                        }
                        this.dialogVisible = true
                      } else {
                        return this.$message.info('暂无报告')
                      }
                    },
                    () => {
                      pdfLoading.close()
                      return this.$message.error('生成报告出错！')
                    }
                  )
                  .catch((err) => {
                    pdfLoading.close()
                    return this.$message.error('生成报告出错！')
                  })
              })
            } else {
              var s = encodeURIComponent(res.data.data.pdfFileName)
              if (res.data.data.pdfFileName.includes('http')) {
                this.report_address = s
              } else {
                this.report_address = PDF_HOST + `/api/v1/webconsole/downloadcenter/download${s}`
              }
              this.dialogVisible = true
            }
          },
          () => {
            pdfLoading.close()
            return this.$message.error('生成报告出错！')
          }
        )
    },

    getSurgeryInfo() {
      if (this.info) {
        this.$api
          .get('/v1/webconsole/useOperationText', {
            menuCode: this.operation
          })
          .then(
            (res) => {
              if (res.data && res.data.data) {
                this.surgeryInfo = res.data.data
              }
            },
            (err) => console.log(err)
          )
      }
    },
    // changeOperateType(val) {
    //   if (val != undefined) {
    //     // 判断值和变量,如果之和变量相等,则不做操作,否则执行
    //     if (this.changeValue == val) {
    //       return
    //     } else {
    //       // 储存我所改变的值
    //       this.changeValue = val
    //       if (val == '二尖瓣修复术') {
    //         this.repairSystemIsShow = true
    //       } else {
    //         this.repairSystemIsShow = false
    //       }
    //     }
    //   }
    // },

    // 提交表单
    open_save() {
      if (this.isReadonly) {
        return this.$message({
          message: '报告已签名,无法进行编辑操作',
          type: 'warning'
        })
      }
      this.$confirm('保存二尖瓣基本信息?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._save()
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消保存'
          })
        })
    },
    _save(callback) {
      this.formData.items = [] // 重置item
      let basicAorticInfo = { ...this.MitralTricuspidInfo, ...this.$refs.Transfusion.aorticInfo } // 全部基础信息
      // let complicationForm = this.$refs.ComplicationForm.aorticInfo
      let mitralTricuspidInformation = this.$refs.MitralTricuspidInformation.basicForm
      // complicationForm = {
      //   ...complicationForm,
      //   complication: complicationForm?.complication?.join('|') || '',
      //   postDiagnosis: complicationForm.postDiagnosis || ''
      // } // 并发症
      this.loading = true
      let differentFields = {}

      for (let item in basicAorticInfo) {
        if (this.universalStudy?.hasOwnProperty(item)) {
          this.universalStudy[item] = basicAorticInfo[item]
        }
        if (!this.universalStudy?.hasOwnProperty(item)) {
          differentFields[item] = basicAorticInfo[item]
        }
        if (basicAorticInfo[item] && (item === 'sheathGoInTime' || item === 'sheathOutTime')) {
          this.universalStudy[item] = basicAorticInfo[item] + ':00'
        }
      }

      // ----- 私有字段items进行数据组装 ----
      for (let i in differentFields) {
        this.formData.items.push({
          elementId: i,
          value: differentFields[i],
          extendTable: null
        })
      }
      // 缘对缘修复
      for (let i in mitralTricuspidInformation) {
        this.formData.items.push({
          elementId: i,
          value: mitralTricuspidInformation[i],
          extendTable: null
        })
      }

      this.formData.universalStudy = {
        // 基础字段
        ...this.universalStudy,
        // ...complicationForm,
        moduleId: this.menuListData.moduleId,
        operationId: this.info.uuid,
        treatmentPlanId: this.$refs.PostMedicalDiagnosis.treatmentPlanId, // 术后诊疗计划
        treatmentPlan: this.$refs.PostMedicalDiagnosis.treatmentPlan, // 术后诊疗计划
        studyProcess: this.$refs.SurgeryProcedure.studyRecord, // 手术经过
        postoperativeDestination: this.$refs.PostMedicalDiagnosis.treatmentPlan // 术后去向
        // studyResult: this.$refs.SurgeryResult.studyResult // 手术结果
      } // universalStudy字段
      this.handleData()
      let url = `/v1/webconsole/eform/universal/save/${this.info.uuid}/${this.menuListData.moduleId}`
      this.$api
        .post(url, this.formData)
        .then(
          ({ data: data }) => {
            if (data.status === 200) {
              this.formData.id = data.data.id
              this.loading = false
              this.$bus.$emit('saveFormInfo')
              !callback && this.$message.success('保存成功')
            }
          },
          () => {
            this.loading = false
            return !callback && this.$message.error('保存出错')
          }
        )
        .finally(() => {
          callback && callback()
        })
    },
    handleData() {
      let mitralTricuspidTableArr = []
      let echoCardiogramArr = []
      if (this.$refs.MitralTricuspidInformation.mitralTricuspidTableData.length !== 0) {
        // 缘对缘修复
        mitralTricuspidTableArr =
          this.$refs.MitralTricuspidInformation.mitralTricuspidTableData.map((element) =>
            this.convertElement(element)
          )
      }
      // 术后即刻超声心动图检查
      if (this.echoTypeIsShow !== false) {
        echoCardiogramArr = Array.from(Array(1)).map((_, index) => {
          // 在这里进行 map 操作
          return this.convertElement(this.cardiogram)
        })
      }

      this.itemArr.forEach((item) => {
        if (item.elementId === 'mitralEcho') {
          item.extendTable = echoCardiogramArr
        }
        if (item.elementId === 'mitralTeer') {
          item.extendTable = mitralTricuspidTableArr
        }
      })

      this.formData.items = [...this.formData.items, ...this.itemArr]
    },
    convertElement(element) {
      const arr = []
      delete element?.ids

      for (const i in element) {
        // 直接push即可
        arr.push({
          elementId: i,
          value: element[i],
          extendTable: null
        })
      }

      return arr
    }
  }
}
</script>

<style lang="scss" scoped>
.save-button {
  padding-left: 10px;
  margin-right: 0.5rem;
}
.valve-module-main-content {
  .body::-webkit-scrollbar {
    width: 12px;
  }
  .body::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  .body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
  }
  .body::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  .body {
    flex: 1;
    overflow: auto;
    padding: 0 10px 20px;
    background: #eff0f7;

    .inner-title {
      padding: 5px;
      font-size: 16px;
      font-weight: bold;
    }
    .button-panel {
      width: 100%;
      bottom: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-around;
      margin-top: 20px;
    }
    .valve-basic-information-label {
      margin-top: 20px;
      margin-bottom: 20px;
      padding: 15px 10px 5px 10px;
      border-radius: 5px;
      position: relative;
      background-color: #fff !important;
      box-shadow: 0 0.125rem 0.75rem 0 rgba(0, 0, 0, 0.1);

      .input-form-item {
        min-width: 28%;
        margin: 0.4rem 1.5rem;
        display: flex;
        align-items: center;

        .input_has_unit {
          width: 200px;
          margin: 0;
        }

        .input_has_unit_text {
          margin: 0;
          width: 280px;
        }

        .el-date-editor.el-input {
          width: 200px;
        }
      }

      .input-form-item {
        min-width: 30%;
        margin: 0.4rem 0 0.4rem 0.5rem;
        display: flex;
        align-items: center;
      }

      @media screen and (max-width: 1180px) {
        .input-form-item {
          min-width: 40%;
        }
      }

      .input-form-item-label {
        width: 120px;
      }

      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
        display: flex;
        align-items: center;
      }
    }
    .valve-basic-information {
      position: relative;
      .inner-title {
        position: absolute;
        top: -12px;
        left: 12px;
        background: #fff;
        padding: 0 10px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        padding-top: 5px;
      }
      .valve-basic-information-head {
        margin: 5px 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .save-button {
          margin-right: 0.5rem;
          padding-left: 10px;
        }
      }
      .valve-basic-information-body {
        display: flex;
        margin: 10px 0;

        .el-card {
          /* margin: 0 10px; */
          margin-right: 10px;
        }

        .el-card:last-child {
          margin: 0;
        }

        .input-form-card {
          width: 100%;
          display: flex;
          flex-direction: column;
        }

        .input-form {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
        }

        .input-form-item {
          min-width: 28%;
          margin: 0.4rem 1.5rem;
          display: flex;
          align-items: center;

          .input_has_unit {
            width: 200px;
            margin: 0;
          }

          .input_has_unit_text {
            margin: 0;
            width: 280px;
          }

          .el-date-editor.el-input {
            width: 200px;
          }
        }

        .input-form-item {
          min-width: 30%;
          margin: 0.4rem 0 0.4rem 0.5rem;
          display: flex;
          align-items: center;
        }

        @media screen and (max-width: 1180px) {
          .input-form-item {
            min-width: 40%;
          }
        }

        .input-form-item-label {
          width: 120px;
        }
      }
    }
  }
  .remarkInfo {
    .remarkTitle {
      font-weight: bold;
      font-size: 16px;
      margin: 0 10px 10px;
    }

    .input_has_unit_text {
      width: 600px;
      margin: 0 20px;
    }
  }
}
</style>
