<style scoped lang="less">
.top-item-row {
  margin: 10px 15px;
  display: flex;
  justify-content: space-between;
}
.item-row {
  width: 33%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin: 0 10px;
}
.item-row > span {
  margin: 0 15px;
}
.button-panel {
  width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
}
</style>

<template>
  <CmDialogBorder size="small" :zIndex="zIndex" title="缘对缘修复" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="item-row">
        <span>夹合器型号</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            v-model="mitralTricuspidTableInfo.clipModel"
          />
        </div>
      </div>
      <div class="item-row">
        <span>夹合器位置 前瓣</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/MT_aClipLocation"
          :value.sync="mitralTricuspidTableInfo.aClipLocation"
        />
      </div>
      <div class="item-row">
        <span>夹合器位置 后瓣</span>
        <cqt-select
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/MT_pClipLocation"
          :value.sync="mitralTricuspidTableInfo.pClipLocation"
        />
      </div>
    </div>
    <div class="top-item-row">
      <div style="display: flex; align-items: center; width: 33%; margin: 0 0.625rem">
        <span>是否成功</span>
        <cqt-select
          style="margin-left: 6px"
          :isReadonly="isReadonly"
          activeUrl="/v1/webconsole/comboData/get/MT_success"
          :value.sync="mitralTricuspidTableInfo.success"
          @update:value="changeSuccess"
        />
      </div>
      <div class="item-row">
        <template v-if="changeValueIsShow === true">
          <span>失败原因</span>
          <div>
            <cqt-input
              :isReadonly="isReadonly"
              class="input_has_unit"
              placeholder="请输入"
              type="text"
              size="mini"
              v-model="mitralTricuspidTableInfo.failReason"
            />
          </div>
        </template>
      </div>
      <div class="item-row"></div>
    </div>
    <div class="top-item-row">
      <div class="item-row">
        <span>输送系统型号</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            v-model="mitralTricuspidTableInfo.conveyingSystemModel"
          />
        </div>
      </div>
      <div class="item-row">
        <span>跨瓣器型号</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="text"
            size="mini"
            v-model="mitralTricuspidTableInfo.transvalvularDeviceModel"
          />
        </div>
      </div>
      <div class="item-row">
        <span>左房压力</span>
        <div>
          <cqt-input
            :isReadonly="isReadonly"
            class="input_has_unit"
            placeholder="请输入"
            type="number"
            size="mini"
            unit="mmHg"
            v-model="mitralTricuspidTableInfo.pmlLen"
          />
        </div>
      </div>
    </div>

    <div class="button-panel">
      <el-button
        <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
        v-show="!isReadonly"
      >
        保存
      </el-button>
      <el-button
        type="danger"
        class="common-button commonBtn"
        icon="el-icon-close"
        v-debounce="Close"
      >
        关闭
      </el-button>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { mapState } from 'vuex'
import deepClone from '../../../../plugins/clone'

// cqt
import CqtInput from '../../../../components/cqt/CqtInput.vue'
import CqtSelect from '../../../../components/cqt/CqtSelect.vue'

export default {
  name: 'MitralTricuspidDialog',
  components: {
    CmDialogBorder,

    CqtSelect,
    CqtInput
  },
  props: {
    isReadonly: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      changeValue: '',
      changeValueIsShow: false,
      mitralTricuspidTableInfo: {
        mitralTricuspidId: '', //主表ID
        operationId: null, //手术ID
        clipModel: null, //夹合器型号
        success: null, //是否成功(是失败原因/否)
        failReason: null, //失败原因
        conveyingSystemModel: null, //输送系统型号
        transvalvularDeviceModel: null, //跨瓣器型号
        pmlLen: null, //左房压力(mmHg)
        aClipLocation: null, //夹合器位置 前瓣
        pClipLocation: null //夹合器位置 后瓣
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    changeSuccess(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
          if (val == '是') {
            this.changeValueIsShow = false
          } else if (val == '否') {
            this.changeValueIsShow = true
          }
        }
      }
    },
    Show(bloodVessel) {
      this.mitralTricuspidTableInfo = {}
      if (bloodVessel) this.mitralTricuspidTableInfo = deepClone(bloodVessel)
      if (this.mitralTricuspidTableInfo.success == '是') {
        this.changeValueIsShow = false
      } else {
        if (this.mitralTricuspidTableInfo.success == '否') {
          this.changeValueIsShow = true
        }
      }
      this.isShow = true
    },
    Save() {
      if (!this.mitralTricuspidTableInfo.clipModel) {
        return this.$message.warning('夹合器型号不能为空')
      }
      if (!this.changeValueIsShow) {
        this.mitralTricuspidTableInfo.failReason = ''
      }
      this.$emit('save', this.mitralTricuspidTableInfo)
      this.Close()
      if (JSON.parse(localStorage.getItem('imgLabel'))) {
        localStorage.removeItem('imgLabel')
      }
    },

    Close() {
      this.isShow = false
      // this.changeValueIsShow = false
    }
  },

  computed: {
    ...mapState({
      currentPatient: (state) => state.currentPatient
    })
  }
}
</script>
